<template>
  <div>
    <PhoneTemplate class="d-flex d-md-none" />
    <div class="d-none d-md-block background_admin pt-0">
      <AppBar
        :p_privilege="m_privilege"
        :p_route="p_route"
      />
      <div v-if="m_loaded">
        <AdminProjectTable v-if="p_route == ROUTE_ADMIN_PROJECTS" />
        <UserTable v-if="p_route == ROUTE_ADMIN_USERS" />
        <AdminBillingTable v-if="p_route == ROUTE_ADMIN_BILLING" />
        <ConfigTable v-if="p_route == ROUTE_ADMIN_CONFIGS" />
        <UserEmailSummary v-if="p_route == ROUTE_ADMIN_USER_EMAIL_SUMMARY" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AdminBillingTable from "@/components/admin/tables/AdminBillingTable.vue";
import AdminProjectTable from "@/components/admin/tables/AdminProjectTable.vue";
import AppBar from "@/components/shared/AppBar.vue";
import AddressHelper from "@/helpers/AddressHelper";
import { AuthHelper } from "@/helpers/AuthHelper";
import UserHelper from "@/helpers/UserHelper";
import WinnoveHelper from "@/helpers/WinnoveHelper";
import {
  ROUTE_ADMIN_BILLING,
  ROUTE_ADMIN_CONFIGS,
  ROUTE_ADMIN_PROJECTS,
  ROUTE_ADMIN_USERS,
  ROUTE_ADMIN_USER_EMAIL_SUMMARY,
} from "@/router";
import { Privilege } from "@winnove/vue-wlib/enums";
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

export default defineComponent({
  name: "Admin",
  components: {
    AppBar,
    AdminProjectTable,
    AdminBillingTable,
  },
  props: {
    p_route: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const m_privilege = ref(Privilege.FREEMIUM);
    const m_loaded = ref(false);

    onUnmounted(() => {
      WinnoveHelper.clearAllWithoutSession();
    });

    onMounted(() => {
      (async () => {
        m_privilege.value = AuthHelper.getLoggedUser().privilege;

        // Clean all without token.
        WinnoveHelper.clearAllWithoutSession();
        let session = WinnoveHelper.getSession();
        session.clear();
        WinnoveHelper.setSession(session);

        // Fetch from server.
        await WinnoveHelper.fetchConfig();
        await UserHelper.fetchUsers();
        await AddressHelper.fetchAddresses();
        m_loaded.value = true;
      })();
    });

    return {
      ROUTE_ADMIN_PROJECTS,
      ROUTE_ADMIN_USERS,
      ROUTE_ADMIN_CONFIGS,
      ROUTE_ADMIN_BILLING,
      ROUTE_ADMIN_USER_EMAIL_SUMMARY,
      Privilege,
      m_privilege,
      m_loaded,
    };
  },
});
</script>

<style lang="scss" scoped>
.background_admin {
  background-color: #f6f9fd;
}
</style>
