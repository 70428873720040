import NotificationPreferences from "@/models/NotificationPreferences";
import axios, { AxiosResponse } from "axios";
import { useRepo } from "pinia-orm";

export default class NotificationPreferencesHelper {
  public static async updateNotificationPreferences(
    p_preferences: NotificationPreferences
  ): Promise<void> {
    // Update user preferences.
    await axios
      .put("notifications_preferences/" + p_preferences.id, {
        notificationpreferences: p_preferences.serialize(),
      })
      .then((p_response: AxiosResponse) => {
        useRepo(NotificationPreferences)
          .where("id", p_preferences.id)
          .update(p_preferences);
      });
  }

  public static async createNotificationPreferences(
    p_preferences: NotificationPreferences
  ): Promise<void> {
    // create user preferences.
    p_preferences.id = null;
    await axios
      .post("notifications_preferences/", {
        notificationpreferences: p_preferences.serialize(),
      })
      .then((p_response: AxiosResponse) => {
        const notificationpreferences = p_response.data.notificationPreferences;
        const notificationPreferencesRepo = useRepo(NotificationPreferences);
        notificationPreferencesRepo.flush();
        notificationPreferencesRepo.save(notificationpreferences);
      });
  }

  static async fetchNotificationPreferences(userId: number): Promise<void> {
    const preferencesStored = useRepo(NotificationPreferences).all();
    if (preferencesStored.length > 0) {
      return;
    }
    return await axios
      .get("notifications_preferences/" + userId)
      .then((p_response: AxiosResponse) => {
        const preferences =
          p_response.data.preferences ?? new NotificationPreferences();
        preferences.userId = userId;
        const preferencesRepo = useRepo(NotificationPreferences);
        preferencesRepo.fresh(preferences);
      });
  }

  static getNotificationPreferences(userId: number): NotificationPreferences {
    const preferencesStored = useRepo(NotificationPreferences)
      .where("userId", userId)
      .first();
    return preferencesStored || new NotificationPreferences({ userId });
  }
}
