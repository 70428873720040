import PaginationFilter from "@/components/dashboard/tables/filterChip/PaginationFilter";
import EmailHistory from "@/models/EmailHistory";
import axios, { AxiosResponse } from "axios";
import WinnoveHelper, { DataTableOptions } from "./WinnoveHelper";

export default class EmailHistoryHelper {
  static async getEmailHistory(
    userId: number,
    p_options?: DataTableOptions,
    p_filters?: Array<PaginationFilter>
  ): Promise<{ emailHistory: EmailHistory[]; count: number }> {
    const filters: { [key: string]: Array<number> } = {};
    if (p_filters) {
      for (const filter of p_filters) {
        filters[filter.dbQuery] = filter.getCheckedFilterValues();
      }
    }
    const serverOptions = p_options
      ? WinnoveHelper.dataTableOptions2ServerDataOptions(p_options)
      : {};

    const url: string = `email_history/${userId}`;

    return await axios
      .get(url, {
        params: {
          ...serverOptions,
          search: p_options?.search ?? "",
          filters: JSON.stringify(filters),
        },
      })
      .then(async (p_response: AxiosResponse) => {
        const emailHistory: any = p_response.data.emailHistory;
        const count: number = p_response.data.count;
        return {
          emailHistory: emailHistory,
          count: count,
        };
      });
  }
}
