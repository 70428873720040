<template>
  <w-container
    fluid
    v-if="m_mounted"
  >
    <w-data-table-server
      class="table_pack text-primary"
      :headers="m_headers"
      :items="getRemainingBillings()"
      :search="m_search"
      item-key="id"
      :items-per-page="m_options.itemsPerPage"
      :items-length="m_totalCount"
      :page="m_options.page"
      :sortBy="m_options.sortBy"
      v-model:expanded="m_expanded"
      show-expand
      expand-on-click
      @update:expanded="expandFunction"
      :loading="m_isLoading"
      @update:options="optionsChanged"
    >
      <template v-slot:top>
        <w-text-field
          v-model="m_search"
          label="Rechercher"
          class="mx-4 pt-4"
          :prepend-inner-icon="mdiMagnify"
        ></w-text-field>
      </template>
      <template #[`item.lastName`]="{ item }">
        <span class="text-subtitle-2 text-primary"
          >Dr. {{ item.lastName }}</span
        >
      </template>
      <template #[`item.userId`]="{ item }">
        <w-btn
          :to="
            '/' + ROUTE_BILLING_USER.replace(':userId', item.userId.toString())
          "
          >Voir la vue utilisateur</w-btn
        >
      </template>
      <template v-slot:expanded-row="{ item }">
        <td
          colspan="3"
          class="pa-4"
        >
          <w-row>
            <!-- Historique des consommations -->
            <w-col>
              <w-data-table-server
                class="sub_table_pack text-primary"
                :headers="m_historyTableHeaders"
                :items="getUserHistory(item.userId)"
                item-key="id"
                loading-text="Chargement de l'historique en cours..."
                no-data-text="Aucun historique"
                no-results-text="Aucun historique"
                :items-per-page="m_optionsHistory.itemsPerPage"
                :items-length="m_totalCountHistoryConsumption"
                :page="m_optionsHistory.page"
                :sortBy="m_optionsHistory.sortBy"
                density="compact"
                @update:options="optionsHistoryChanged(item.userId, $event)"
              >
                <template v-slot:top>
                  <h3>Historique des consommations</h3>
                </template>
                <template #[`item.date`]="{ item }">
                  <span>{{ DateHelper.formatDate(item.date) }}</span>
                </template>
                <template #[`item.comment`]="{ item }">
                  <span>{{ formatComment(item.comment) }}</span>
                </template>
              </w-data-table-server>
            </w-col>

            <!-- Historique des facturations -->
            <w-col class="mt-5">
              <w-data-table-server
                class="sub_table_pack text-primary"
                :headers="m_billsHistoryTableHeaders"
                :items="getUserBillsHistory(item.userId)"
                loading-text="Chargement de l'historique en cours..."
                item-key="id"
                no-data-text="Aucun historique"
                no-results-text="Aucun historique"
                :items-per-page="m_optionsBill.itemsPerPage"
                :items-length="m_totalCountHistoryInvoice"
                :page="m_optionsBill.page"
                :sortBy="m_optionsBill.sortBy"
                density="compact"
                @update:options="optionsBillChanged(item.userId, $event)"
              >
                <template v-slot:top>
                  <w-row>
                    <h3>Historique des facturations</h3>
                    <w-spacer></w-spacer>
                    <w-btn
                      rounded
                      class="mr-5 mb-5"
                      @click="openNewBillingDialog(item.userId)"
                    >
                      + Ajouter
                    </w-btn>
                  </w-row>
                </template>
                <template #[`item.date`]="{ item }">
                  <span>{{ DateHelper.formatDate(item.date) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <w-icon
                    small
                    @click="deleteBilling(item)"
                    :icon="mdiDelete"
                  >
                  </w-icon>

                  <w-icon
                    small
                    @click="editBilling(item)"
                    :icon="mdiPencil"
                  >
                  </w-icon>
                </template>
                <template v-slot:item.invoicePath="{ item }">
                  <w-tooltip
                    :text="getInvoiceList(item.invoicePath)"
                    location="bottom"
                  >
                    <template #activator="{ props }">
                      <w-icon
                        v-if="item.invoicePath && item.invoicePath.length > 2"
                        :icon="mdiFilePdfBox"
                        v-bind="props"
                      >
                      </w-icon>
                    </template>
                  </w-tooltip>
                </template>

                <template v-slot:item.paid="{ item }">
                  <w-switch
                    v-if="item.invoiced"
                    :modelValue="!!item.paid"
                    hide-details
                    color="primary"
                    density="compact"
                    @change="updateBillingPaidValue(item)"
                    @update:modelValue="item.paid = $event"
                  ></w-switch>
                </template>
              </w-data-table-server>
            </w-col>
          </w-row>
        </td>
      </template>
    </w-data-table-server>

    <!-- New billing dialog -->
    <BaseDialog
      ref="newBillingDialog"
      :p_title="
        m_isInEdit
          ? 'Modification de la facturation'
          : 'Ajouter une facturation'
      "
      max-width="600px"
      p_noPadding
      @close="resetBillingForm()"
    >
      <w-form
        ref="formNewBilling"
        class="mt-4"
        v-model="m_isNewPackFormValid"
        @submit.prevent="m_isInEdit ? confirmEditBilling() : addBilling()"
        nowrap
      >
        <w-card-text>
          <!-- Praticien  -->
          <w-row>
            <w-col>
              <w-autocomplete
                v-model="m_newBilling.userId"
                :items="m_users"
                :item-title="(itm) => 'Dr.' + itm.lastName"
                :item-value="(itm) => itm.id"
                :rules="[(v) => (v && v > -1) || 'Praticien requis']"
                no-data-text="Aucun praticien"
                label="Rechercher un praticien"
              >
              </w-autocomplete>
            </w-col>
          </w-row>

          <!-- Date de facturation  -->
          <w-row>
            <w-col>
              <DatePicker
                @change="changenewBillingDate"
                :p_privilege="Privilege.ADMIN"
                :p_date="m_newBilling.date"
                :p_label="'Date de facturation'"
                :notempty="true"
                required
              />
            </w-col>
          </w-row>

          <!-- Pack buttons -->
          <w-row class="my-4 justify-space-around">
            <w-btn
              variant="outlined"
              rounded
              @click="setNewBillingPack(25)"
              class="mx-1"
              >Pack 25</w-btn
            >
            <w-btn
              variant="outlined"
              rounded
              @click="setNewBillingPack(50)"
              class="mx-1"
              >Pack 50</w-btn
            >
            <w-btn
              variant="outlined"
              rounded
              @click="setNewBillingPack(100)"
              class="mx-1"
              >Pack 100</w-btn
            >
            <w-btn
              variant="outlined"
              rounded
              @click="setNewBillingPack(200)"
              class="mx-1"
              >Pack 200</w-btn
            >
          </w-row>

          <w-row class="">
            <!-- Commentaire  -->
            <w-col>
              <w-text-field
                v-model="m_newBilling.comment"
                label="Commentaire"
                :rules="[(v) => (v && !!v.trim()) || 'Commentaire requis']"
                required
              ></w-text-field>
            </w-col>
            <!-- Jetons -->
            <w-col>
              <w-text-field
                v-model="m_newBilling.credits"
                label="Jetons"
                :rules="[(v) => !!v || 'Jetons requis']"
                required
                type="number"
                onkeydown="return event.keyCode !== 69"
              ></w-text-field>
            </w-col>
          </w-row>
          <!-- Toggle facturation -->
          <w-row>
            <w-col>
              <w-tooltip
                text="Fils vendus = coché, Fils offerts / garantie = décoché. Si coché à tord, un client pensera qu'il lui reste une facture à payer car cela s'affiche dans son espace facturation."
                location="bottom"
              >
                <template #activator="{ props }">
                  <w-switch
                    v-bind="props"
                    v-model="m_newBilling.invoiced"
                    hide-details
                    color="primary"
                    label="Facturé - A ne cocher que si une facture a été émise"
                    required
                    :append-outer-icon="mdiInformation"
                  ></w-switch>
                </template>
              </w-tooltip>
            </w-col>
          </w-row>
          <!-- Intégration de la facture -->
          <w-row>
            <w-col>
              <InvoiceArea
                p_title="Factures et justificatifs associées (.pdf)"
                @files-selected="onFilesSelected"
              />
            </w-col>
          </w-row>

          <w-row v-if="m_isInEdit && m_newBillingInvoicePaths.length > 0">
            <w-col cols="12">
              <h3>Facture(s) associée(s)</h3>
            </w-col>
            <w-col>
              <w-list class="bg-white">
                <w-list-item
                  v-for="invoice in m_newBillingInvoicePaths"
                  @click="downloadSpecificInvoice(m_newBilling, invoice.path)"
                >
                  <w-list-item-title>{{ invoice.fileName }}</w-list-item-title>

                  <template v-slot:append>
                    <w-progress-circular
                      v-if="
                        m_downloadingFile &&
                        invoice.fileName === m_downloadingFileName
                      "
                      indeterminate
                      size="24"
                    >
                    </w-progress-circular>
                    <div v-else>
                      <w-icon
                        color="primary"
                        @click.stop="
                          downloadSpecificInvoice(m_newBilling, invoice.path)
                        "
                        :icon="mdiFileDownload"
                      >
                      </w-icon>
                      <w-icon
                        color="primary"
                        @click.stop="
                          deleteAttachement(m_newBilling, invoice.path)
                        "
                        :icon="mdiDelete"
                      >
                      </w-icon>
                    </div>
                  </template>
                </w-list-item>
              </w-list>
            </w-col>
          </w-row>
        </w-card-text>

        <w-card-actions class="justify-end">
          <w-btn
            color="primary"
            variant="tonal"
            @click="cancelAddBilling()"
          >
            Annuler
          </w-btn>
          <w-btn
            :disabled="!m_isNewPackFormValid"
            type="submit"
            variant="elevated"
          >
            {{ m_isInEdit ? "Modifier" : "Ajouter" }}
          </w-btn>
        </w-card-actions>
      </w-form>
    </BaseDialog>
  </w-container>
</template>

<script lang="ts">
import BaseDialog from "@/components/shared/BaseDialog.vue";
import DatePicker from "@/components/shared/DatePicker.vue";
import InvoiceArea from "@/components/shared/InvoiceArea.vue";
import BillingHelper from "@/helpers/BillingHelper";
import CookieHelper from "@/helpers/CookieHelper";
import DateHelper from "@/helpers/DateHelper";
import { EnumHelper, EnumSelectEntry } from "@/helpers/EnumHelper";
import UserHelper from "@/helpers/UserHelper";
import { DataTableOptions } from "@/helpers/WinnoveHelper";
import Billing from "@/models/Billing";
import User from "@/models/User";
import { ROUTE_BILLING_USER } from "@/router";
import Logger from "@/shared/logger";
import {
  mdiDelete,
  mdiFileDownload,
  mdiFilePdfBox,
  mdiInformation,
  mdiMagnify,
  mdiPencil,
} from "@mdi/js";
import { Privilege, PrivilegeString } from "@winnove/vue-wlib/enums";
import { useRepo } from "pinia-orm";
import { defineComponent, nextTick, onMounted, reactive, ref } from "vue";

const DEFAULT_OPTIONS: DataTableOptions = {
  page: 1,
  itemsPerPage: 25,
  sortBy: [{ key: "lastName", order: "asc" }],
  groupBy: [],
  search: "",
};

const DEFAULT_OPTIONS_BILLS: DataTableOptions = {
  page: 1,
  itemsPerPage: 25,
  sortBy: [
    { key: "date", order: "desc" },
    { key: "cumulativeCredits", order: "asc" },
  ],
  groupBy: [],
  search: "",
};

const m_headers = [
  {
    title: "Docteur",
    align: "start",
    value: "lastName",
    sortable: true,
    filterable: true,
  },
  {
    title: "Jetons restants",
    align: "start",
    value: "credits",
    sortable: true,
    filterable: false,
  },
  {
    title: "Page facturation utilisateur",
    align: "start",
    value: "userId",
    sortable: true,
    filterable: false,
  },
];

const m_historyTableHeaders = [
  {
    title: "Date",
    align: "start",
    value: "date",
    sortable: true,
    filterable: true,
  },
  {
    title: "Détails",
    align: "start",
    value: "comment",
    sortable: false,
    filterable: false,
  },
  {
    title: "Évolution jetons",
    align: "start",
    value: "credits",
    sortable: false,
    filterable: false,
  },
  {
    title: "# jetons à date",
    align: "start",
    value: "cumulativeCredits",
    sortable: true,
    filterable: false,
  },
];

const m_billsHistoryTableHeaders = [
  {
    title: "Date",
    align: "start",
    value: "date",
    sortable: true,
    filterable: true,
  },
  {
    title: "Détails",
    align: "start",
    value: "comment",
    sortable: true,
    filterable: false,
  },
  {
    title: "Évolution jetons",
    align: "start",
    value: "credits",
    sortable: true,
    filterable: false,
  },
  {
    title: "Actions",
    value: "actions",
    sortable: false,
    filterable: false,
    width: "20%",
  },
  {
    title: "Factures",
    value: "invoicePath",
    sortable: false,
    filterable: false,
  },
  {
    title: "Payé",
    value: "paid",
    sortable: true,
    filterable: false,
  },
];

export default defineComponent({
  name: "BillingTable",
  components: {
    DatePicker,
    BaseDialog,
    InvoiceArea,
  },
  props: {},
  setup(props, context) {
    const formNewBilling = ref<InstanceType<typeof HTMLFormElement> | null>(
      null
    );

    const m_panel = ref(-1);
    const m_users = ref<User[]>([]);
    const m_billings = reactive<Billing[]>([]);
    const m_billingsHistoryConsumption = reactive<Billing[]>([]);
    const m_billingsHistoryInvoice = reactive<Billing[]>([]);
    const m_isNewPackFormValid = ref(true);
    const m_newBilling = ref(new Billing());
    const m_newBillingInvoicePaths = ref<{ fileName: string; path: string }[]>(
      []
    );
    const m_privileges = ref<EnumSelectEntry[]>([]);
    const m_expanded = ref([]);
    const m_isLoading = ref(false);
    const newBillingDialog = ref<InstanceType<typeof BaseDialog> | null>(null);
    const m_isInEdit = ref(false);
    const m_downloadingFile = ref(false);
    const m_downloadingFileName = ref("");

    const m_mounted = ref(false);

    const m_totalCount = ref(0);
    const m_totalCountHistoryConsumption = ref(0);
    const m_totalCountHistoryInvoice = ref(0);

    const m_options = ref<DataTableOptions>(
      CookieHelper.getCookie("AdminBillingDashboardOptionsV3")
        ? JSON.parse(CookieHelper.getCookie("AdminBillingDashboardOptionsV3")!)
        : DEFAULT_OPTIONS
    );
    const m_optionsHistory = ref<DataTableOptions>(
      CookieHelper.getCookie("AdminBillingHistoryDashboardOptionsV3")
        ? JSON.parse(
            CookieHelper.getCookie("AdminBillingHistoryDashboardOptionsV3")!
          )
        : DEFAULT_OPTIONS_BILLS
    );
    const m_optionsBill = ref<DataTableOptions>(
      CookieHelper.getCookie("AdminBillingBillDashboardOptionsV3")
        ? JSON.parse(
            CookieHelper.getCookie("AdminBillingBillDashboardOptionsV3")!
          )
        : DEFAULT_OPTIONS_BILLS
    );
    const selectedFiles = ref<File[]>([]);

    function onFilesSelected(files: File[]) {
      selectedFiles.value = files;
      m_newBilling.value.invoiced = true;
    }

    const m_search = ref<string>(m_options.value.search);
    function resetBillingForm() {
      m_newBilling.value = new Billing();
      m_newBillingInvoicePaths.value = [];
      selectedFiles.value = [];
      m_isInEdit.value = false;
    }

    onMounted(async () => {
      m_mounted.value = false;
      m_privileges.value = EnumHelper.getSelectListAsArray(
        Privilege,
        PrivilegeString
      );
      m_users.value = useRepo(User).all();
      if (m_users.value.length === 0) {
        await UserHelper.fetchUsers();
        m_users.value = useRepo(User).all();
      }
      await refresh();
      m_mounted.value = true;
    });

    async function refresh(): Promise<void> {
      m_isLoading.value = true;
      // Get billing summary
      await BillingHelper.getRemainingTokens(undefined, m_options.value).then(
        (results: any) => {
          m_totalCount.value = results.count;
          m_billings.splice(0, m_billings.length, ...results.remaining);
        }
      );
      m_isLoading.value = false;
    }

    async function refreshHistory(userId: number): Promise<void> {
      m_isLoading.value = true;
      await BillingHelper.getBillingHistory(
        userId,
        m_optionsHistory.value
      ).then((results: { billings: Billing[]; count: number }) => {
        m_totalCountHistoryConsumption.value = results.count;
        m_billingsHistoryConsumption.splice(
          0,
          m_billingsHistoryConsumption.length,
          ...results.billings
        );
      });
      m_isLoading.value = false;
    }

    async function refreshBill(userId: number): Promise<void> {
      m_isLoading.value = true;
      await BillingHelper.getBillingHistoryInvoice(
        userId,
        m_optionsBill.value
      ).then((results: { billings: Billing[]; count: number }) => {
        m_totalCountHistoryInvoice.value = results.count;
        m_billingsHistoryInvoice.splice(
          0,
          m_billingsHistoryInvoice.length,
          ...results.billings
        );
      });
      m_isLoading.value = false;
    }

    function getRemainingBillings(): Billing[] {
      return m_billings as Billing[];
    }

    function getUserHistory(p_userId: number): Billing[] {
      const filteredBillings = (
        m_billingsHistoryConsumption as Billing[]
      ).filter((billing: Billing) => {
        if (billing.userId) return billing.userId === p_userId;
        else return false;
      });
      return filteredBillings;
    }

    function getUserBillsHistory(p_userId: number): Billing[] {
      const filteredBillings = (m_billingsHistoryInvoice as Billing[]).filter(
        (billing: Billing) => {
          // only keep bills
          if (billing.orderId) return false;

          if (billing.userId) return billing.userId === p_userId;
          else return false;
        }
      );
      return filteredBillings;
    }

    function cancelAddBilling(): void {
      resetBillingForm();
      if (m_isInEdit.value) {
        m_isInEdit.value = false;
        newBillingDialog.value?.close();
        return;
      }

      newBillingDialog.value?.close();
    }

    async function editBilling(item: Billing) {
      resetBillingForm();
      m_isInEdit.value = true;
      nextTick();
      m_newBilling.value = JSON.parse(JSON.stringify(item));
      m_newBilling.value.invoiced = Boolean(m_newBilling.value.invoiced);
      if (!m_newBilling.value.invoicePath)
        m_newBilling.value.invoicePath = JSON.stringify([]);
      m_newBillingInvoicePaths.value = JSON.parse(
        m_newBilling.value.invoicePath!
      );
      newBillingDialog.value?.show();
    }

    async function downloadSpecificInvoice(item: Billing | null, path: string) {
      if (!item || !path) return;
      const filename = path.split("/").pop() || "facture.pdf";

      m_downloadingFile.value = true;
      m_downloadingFileName.value = filename;
      try {
        const invoiceBlob = await BillingHelper.downloadInvoice(item, path);

        const link = document.createElement("a");
        link.href = URL.createObjectURL(invoiceBlob);
        link.download = filename;
        link.click();

        URL.revokeObjectURL(link.href);
      } catch (error) {
        Logger.getInstance().error(
          "Erreur lors du téléchargement de la facture"
        );
      } finally {
        m_downloadingFile.value = false;
        m_downloadingFileName.value = "";
      }
    }

    async function deleteAttachement(item: Billing, path: string) {
      if (!item || !path) return;

      const newInvoicePaths = m_newBillingInvoicePaths.value.filter(
        (invoice) => invoice.path !== path
      );
      m_newBillingInvoicePaths.value = newInvoicePaths;
      m_newBilling.value.invoicePath = JSON.stringify(newInvoicePaths);
    }

    async function confirmEditBilling(): Promise<void> {
      await BillingHelper.updateBilling(
        m_newBilling.value as Billing,
        selectedFiles.value
      );
      Logger.getInstance().success("Facturation modifiée avec succès");
      refresh();
      refreshBill(m_newBilling.value.userId as number);
      refreshHistory(m_newBilling.value.userId as number);

      m_isInEdit.value = false;
      newBillingDialog.value?.close();
    }

    async function addBilling(): Promise<void> {
      m_newBilling.value.credits = Number(m_newBilling.value.credits);

      await BillingHelper.addBilling(
        m_newBilling.value as Billing,
        selectedFiles.value
      ).then((response) => {
        Logger.getInstance().success("Facturation ajoutée avec succès");
        formNewBilling.value?.reset();
        m_newBilling.value = new Billing();
        newBillingDialog.value?.close();
        refresh();
        refreshBill(response.userId as number);
        refreshHistory(response.userId as number);
      });
    }

    function changenewBillingDate(p_date: string): void {
      m_newBilling.value.date = p_date;
    }

    function setNewBillingPack(p_nb: number): void {
      m_newBilling.value.comment = "Pack de " + p_nb + " jetons";
      m_newBilling.value.credits = p_nb;
    }

    async function deleteBilling(p_billing: Billing): Promise<void> {
      await BillingHelper.deleteBilling(p_billing).then(() => {
        Logger.getInstance().success("Facturation supprimée avec succès");
        refresh();
        refreshBill(p_billing.userId as number);
        refreshHistory(p_billing.userId as number);
      });
    }

    async function updateBillingPaidValue(item: any) {
      await BillingHelper.updateBilling(item);
    }

    function openNewBillingDialog(p_userId: number): void {
      resetBillingForm();
      m_newBilling.value.userId = p_userId;
      m_newBilling.value.invoiced = false;
      newBillingDialog.value?.show();
    }

    function optionsChanged(options: DataTableOptions): void {
      m_options.value = options;
      _onOptionsChanged();
    }

    async function _onOptionsChanged(): Promise<void> {
      CookieHelper.setCookie("AdminBillingDashboardOptionsV3", m_options.value);

      if (m_options.value.search === null) m_options.value.search = "";
      refresh();
    }

    function optionsBillChanged(
      userId: number,
      options: DataTableOptions
    ): void {
      m_optionsBill.value = options;
      _onOptionsBillChanged();
      refreshBill(userId);
    }

    async function _onOptionsBillChanged(): Promise<void> {
      CookieHelper.setCookie(
        "AdminBillingBillDashboardOptionsV3",
        m_optionsBill.value
      );
    }

    function optionsHistoryChanged(
      userId: number,
      options: DataTableOptions
    ): void {
      m_optionsHistory.value = options;
      _onOptionsHistoryChanged();
      refreshHistory(userId);
    }

    async function _onOptionsHistoryChanged(): Promise<void> {
      CookieHelper.setCookie(
        "AdminBillingHistoryDashboardOptionsV3",
        m_optionsHistory.value
      );
    }

    const expandFunction = (val: any) => {
      m_expanded.value = [val[m_expanded.value.length - 1]];
      m_optionsHistory.value = DEFAULT_OPTIONS_BILLS;
      m_optionsBill.value = DEFAULT_OPTIONS_BILLS;
    };

    function formatComment(value: string) {
      const splittedComment = value.split(" ");
      const reference = splittedComment[splittedComment.length - 1];
      let comment: string = value;
      if (value.includes("Maxillaire") || value.includes("Mandibulaire")) {
        comment = value.includes("Maxillaire") ? "U" : "L";
        comment += `${reference} `;
        if (value.includes("Annulation")) comment += " annulée";
      }
      return comment;
    }

    function getInvoiceList(invoicePath: string) {
      if (!invoicePath) return "";

      let invoicePaths = [];
      try {
        invoicePaths = JSON.parse(invoicePath);
      } catch (error) {
        invoicePaths = [];
      }
      return invoicePaths.map((invoice: any) => invoice.fileName).join(", ");
    }

    return {
      formNewBilling,
      Privilege,
      PrivilegeString,
      DateHelper,
      m_options,
      m_totalCount,
      m_totalCountHistoryConsumption,
      m_panel,
      m_users,
      m_search,
      m_billings,
      m_billingsHistoryConsumption,
      m_billingsHistoryInvoice,
      m_optionsHistory,
      m_optionsBill,
      m_isNewPackFormValid,
      m_newBilling,
      m_newBillingInvoicePaths,
      m_privileges,
      m_expanded,
      m_isLoading,
      newBillingDialog,
      refresh,
      optionsChanged,
      optionsBillChanged,
      optionsHistoryChanged,
      getUserHistory,
      getUserBillsHistory,
      getRemainingBillings,
      cancelAddBilling,
      addBilling,
      changenewBillingDate,
      setNewBillingPack,
      deleteBilling,
      openNewBillingDialog,
      updateBillingPaidValue,
      m_headers,
      m_historyTableHeaders,
      m_billsHistoryTableHeaders,
      m_totalCountHistoryInvoice,
      mdiMagnify,
      mdiDelete,
      mdiPencil,
      mdiFileDownload,
      mdiFilePdfBox,
      mdiInformation,
      m_mounted,
      expandFunction,
      formatComment,
      selectedFiles,
      onFilesSelected,
      editBilling,
      confirmEditBilling,
      m_isInEdit,
      resetBillingForm,
      downloadSpecificInvoice,
      deleteAttachement,
      m_downloadingFile,
      m_downloadingFileName,
      getInvoiceList,
      ROUTE_BILLING_USER,
    };
  },
});
</script>

<style lang="scss">
.table_pack,
.sub_table_pack {
  line-height: 1;
}
</style>
