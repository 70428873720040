<template>
  <base-dialog
    data-cy="notification-preference-dialog"
    ref="notificationDialog"
    p_title="Préférences de notifications"
    p_subtitle="Personnalisez les types d’e-mails et quand vous les recevez pour rester informé sans être submergé."
    maxWidth="800px"
  >
    <w-form
      ref="notificaitonPreferencesAddress"
      class="mt-2"
    >
      <w-window
        v-model="currentPage"
        class="bg-white pa-1"
      >
        <w-window-item :value="1">
          <h4 class="text-center preference-subtitle">
            Ces e-mails obligatoires garantissent le bon fonctionnement de
            l’application et le traitement des commandes :
          </h4>
          <preference-card
            p_title="Prescription incomplète"
            p_subtitle="La prescription manque d’informations après l'import des fichiers du scanner."
            v-model:p_data="
              m_userNotificationPreferences.prescriptionIncomplete
            "
            :p_canBeDisabled="p_privilege === Privilege.IN_OFFICE"
            class="my-6"
          />
          <preference-card
            p_title="Validation du projet requise"
            p_subtitle="La production attend votre approbation pour lancer la fabrication."
            v-model:p_data="
              m_userNotificationPreferences.projectValidationRequired
            "
            :p_canBeDisabled="p_privilege === Privilege.IN_OFFICE"
            class="my-6"
          />
          <preference-card
            p_title="Commande à compléter"
            p_subtitle="La prescription est incomplète ou des fichiers sont manquants pour finaliser la commande."
            v-model:p_data="m_userNotificationPreferences.orderToComplete"
            class="my-6"
            :p_canBeDisabled="p_privilege === Privilege.IN_OFFICE"
          />
          <preference-card
            p_title="Annulation de commande"
            p_subtitle="Une commande a été annulée et nécessite peut-être votre attention."
            v-model:p_data="m_userNotificationPreferences.orderCancellation"
            :p_canBeDisabled="p_privilege === Privilege.IN_OFFICE"
            class="my-6"
          />
        </w-window-item>
        <w-window-item :value="2">
          <h4 class="text-center preference-subtitle">
            Ces e-mails optionnels fournissent des informations déjà disponibles
            dans iForm. Vous pouvez choisir de les désactiver.
          </h4>
          <preference-card
            p_title="Nouvelle commande reçue"
            p_subtitle="Une nouvelle commande a été enregistrée dans iForm et va être traitée par notre équipe."
            :p_canBeDisabled="true"
            v-model:p_data="m_userNotificationPreferences.newOrderReceived"
            class="my-6"
          />
          <preference-card
            p_title="Expédition en cours"
            p_subtitle="Votre commande est en cours de livraison."
            :p_canBeDisabled="true"
            v-model:p_data="m_userNotificationPreferences.orderShipped"
            class="my-6"
          />
          <preference-card
            p_title="Message non lu"
            p_subtitle="Un nouveau message attend votre réponse dans le chat de l'application."
            :p_canBeDisabled="true"
            v-model:p_data="m_userNotificationPreferences.unreadMessage"
            class="my-6"
          />
          <p class="mb-6 mx-10 text-center">
            Vous pouvez retrouver et modifier à tout moment ces préférences sur
            la page “Mon compte”.
          </p>
        </w-window-item>
      </w-window>
      <w-card-actions>
        <w-row class="d-flex justify-end">
          <w-col
            md="1"
            class="page-number"
          >
            {{ currentPage }}/{{ totalPages }}
          </w-col>
          <w-row
            class="d-flex justify-end"
            style="width: auto"
          >
            <w-col md="2">
              <w-btn
                v-if="currentPage > 1 && currentPage <= totalPages"
                data-cy="address-dialog-btn"
                block
                @click="currentPage--"
                color="wGrey"
                >Précédent</w-btn
              >
            </w-col>
            <w-col md="2">
              <w-btn
                data-cy="address-dialog-btn"
                block
                @click="currentPage == totalPages ? validate() : currentPage++"
              >
                {{ currentPage == totalPages ? "Valider" : "Suivant" }}
              </w-btn>
            </w-col>
          </w-row>
        </w-row>
      </w-card-actions>
    </w-form>
  </base-dialog>
</template>

<script lang="ts">
import BaseDialog from "@/components/shared/BaseDialog.vue";
import { AuthHelper } from "@/helpers/AuthHelper";
import NotificationPreferencesHelper from "@/helpers/NotificationPreferencesHelper";
import NotificationPreferences from "@/models/NotificationPreferences";
import { mdiAccountBox, mdiCheckCircle, mdiPhone } from "@mdi/js";
import { Privilege } from "@winnove/vue-wlib/enums";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "NotificationPreferenceDialog",
  components: { BaseDialog },
  props: {
    p_privilege: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const m_userNotificationPreferences = ref(new NotificationPreferences());
    const notificationDialog = ref<InstanceType<typeof BaseDialog> | null>(
      null
    );
    const currentPage = ref(1);
    const totalPages = ref(2);

    function show(): void {
      notificationDialog.value?.show();
    }

    function hide(): void {
      notificationDialog.value?.close();
    }

    async function close() {
      hide();
    }

    async function validate(): Promise<void> {
      m_userNotificationPreferences.value.userId =
        AuthHelper.getLoggedUser().id;
      await NotificationPreferencesHelper.createNotificationPreferences(
        m_userNotificationPreferences.value as NotificationPreferences
      );
      context.emit("notificationPreferencesUpdated");
      close();
    }

    return {
      show,
      hide,
      close,
      validate,
      mdiAccountBox,
      mdiCheckCircle,
      mdiPhone,
      m_userNotificationPreferences,
      notificationDialog,
      currentPage,
      totalPages,
      Privilege,
    };
  },
});
</script>

<style lang="scss" scoped>
.preference-subtitle {
  padding: 24px 10% 0px 10%;
}

.page-number {
  font-weight: 700;
  color: rgba(var(--v-theme-wDarkGrey));
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}
</style>
