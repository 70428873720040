<template>
  <div
    class="d-flex flex-column py-10"
    style="justify-self: center"
  >
    <h2 class="mb-4">Mon compte</h2>
    <w-form
      ref="formAccount"
      v-model="m_isFormValid"
      @submit.prevent="validateForm()"
      style="max-width: 800px; width: 100%"
      class="mb-6"
    >
      <w-row>
        <w-col>
          <h3 class="mb-2">Informations professionnelles</h3>
          <p class="mb-4">
            Mettez à jour les coordonnées et informations essentielles de votre
            cabinet pour faciliter les communications.
          </p>
        </w-col>
      </w-row>
      <w-row>
        <w-col md="6">
          <w-text-field
            v-model.trim="m_user.lastName"
            label="Nom"
            :prepend-inner-icon="mdiAccountBox"
            :rules="[(v) => (v && !!v.trim()) || 'Nom requis']"
            required
            autofocus
          ></w-text-field>
        </w-col>
        <w-col md="6">
          <w-text-field
            v-model.trim="m_user.firstName"
            label="Prénom"
            :prepend-inner-icon="mdiAccountBox"
            :rules="[(v) => (v && !!v.trim()) || 'Prénom requis']"
            required
          ></w-text-field>
        </w-col>
      </w-row>
      <w-row>
        <w-col md="6">
          <w-text-field
            v-model.trim="m_user.email"
            label="Email"
            :prepend-inner-icon="mdiEmail"
            required
            disabled
          ></w-text-field>
        </w-col>
        <w-col md="6">
          <w-text-field
            v-model.trim="m_user.phoneNumber"
            label="Téléphone"
            :prepend-inner-icon="mdiPhone"
          ></w-text-field>
        </w-col>
      </w-row>
      <w-row>
        <w-col md="12">
          <AddressTextField
            v-model:p_address="m_address.address"
            p_label="Adresse"
          />
        </w-col>
      </w-row>
      <w-row>
        <w-col md="12">
          <AddressTextField
            v-model:p_address="m_address.address2"
            p_label="Adresse suite"
          />
        </w-col>
      </w-row>
      <w-row>
        <w-col md="6">
          <w-text-field
            v-model.trim="m_address.city"
            label="Ville"
          ></w-text-field>
        </w-col>
        <w-col md="6">
          <w-text-field
            v-model.trim="m_address.postalCode"
            label="Code postal"
            onkeydown="return event.keyCode !== 69"
          ></w-text-field>
        </w-col>
      </w-row>
      <w-row>
        <w-col md="12">
          <w-select
            v-model="m_address.country"
            :items="m_countries"
            item-title="name"
            item-value="code"
            hide-details
            class="mb-4"
          ></w-select>
        </w-col>
      </w-row>
      <w-row v-if="isProductionRole">
        <w-col md="12">
          <multiple-date-picker-range
            :p_dates="m_user.unavailableDates"
            :p_min="m_todayDate"
            @change="updateUnavailableDates"
          />
        </w-col>
      </w-row>
      <w-row class="my-8">
        <w-col
          sm="4"
          offset-sm="4"
        >
          <w-btn
            :loading="m_formBeingSubmitted"
            :disabled="!m_isFormValid"
            color="primary"
            type="submit"
            block
            >Enregistrer
          </w-btn>
        </w-col>
      </w-row>
    </w-form>
    <w-form
      v-if="m_user.privilege !== Privilege.ADMIN"
      ref="formNotificationPreferences"
      style="max-width: 800px; width: 100%"
      @submit.prevent="updateNotificationPreferences()"
    >
      <w-row>
        <w-col>
          <h3 class="mb-2">Préférences de notifications</h3>
          <p class="mb-4">
            Personnalisez les types d’e-mails et quand vous les recevez pour
            rester informé sans être submergé.
          </p>
        </w-col>
      </w-row>
      <w-row>
        <w-col>
          <h4>Nécessaires :</h4>
          <preference-card
            p_title="Prescription incomplète"
            p_subtitle="La prescription manque d’informations après l'import des fichiers du scanner."
            v-model:p_data="
              m_userNotificationPreferences.prescriptionIncomplete
            "
            :p_canBeDisabled="m_user.privilege === Privilege.IN_OFFICE"
            class="my-6"
          />
          <preference-card
            p_title="Validation du projet requise"
            p_subtitle="La production attend votre approbation pour lancer la fabrication."
            v-model:p_data="
              m_userNotificationPreferences.projectValidationRequired
            "
            :p_canBeDisabled="m_user.privilege === Privilege.IN_OFFICE"
            class="my-6"
          />
          <preference-card
            p_title="Commande à compléter"
            p_subtitle="La prescription est incomplète ou des fichiers sont manquants pour finaliser la commande."
            v-model:p_data="m_userNotificationPreferences.orderToComplete"
            :p_canBeDisabled="m_user.privilege === Privilege.IN_OFFICE"
            class="my-6"
          />
          <preference-card
            p_title="Annulation de commande"
            p_subtitle="Une commande a été annulée et nécessite peut-être votre attention."
            v-model:p_data="m_userNotificationPreferences.orderCancellation"
            :p_canBeDisabled="m_user.privilege === Privilege.IN_OFFICE"
            class="my-6"
          />
        </w-col>
      </w-row>
      <w-row>
        <w-col>
          <h4>Optionnels :</h4>
          <preference-card
            p_title="Nouvelle commande reçue"
            p_subtitle="Une nouvelle commande a été enregistrée dans iForm et va être traitée par notre équipe."
            :p_canBeDisabled="true"
            v-model:p_data="m_userNotificationPreferences.newOrderReceived"
            class="my-6"
          />
          <preference-card
            p_title="Expédition en cours"
            p_subtitle="Votre commande est en cours de livraison."
            :p_canBeDisabled="true"
            v-model:p_data="m_userNotificationPreferences.orderShipped"
            class="my-6"
          />
          <preference-card
            p_title="Message non lu"
            p_subtitle="Un nouveau message attend votre réponse dans le chat de l'application."
            :p_canBeDisabled="true"
            v-model:p_data="m_userNotificationPreferences.unreadMessage"
            class="my-6"
          />
        </w-col>
      </w-row>
      <w-row class="my-8">
        <w-col
          sm="4"
          offset-sm="4"
        >
          <w-btn
            :loading="m_formBeingSubmitted"
            color="primary"
            type="submit"
            block
            >Enregistrer
          </w-btn>
        </w-col>
      </w-row>
    </w-form>
  </div>
</template>

<script lang="ts">
import AddressTextField from "@/components/shared/AddressTextField.vue";
import AddressHelper from "@/helpers/AddressHelper";
import { AuthHelper } from "@/helpers/AuthHelper";
import CountryHelper from "@/helpers/CountryHelper";
import NotificationPreferencesHelper from "@/helpers/NotificationPreferencesHelper";
import UserHelper from "@/helpers/UserHelper";
import Address from "@/models/Address";
import Country from "@/models/Country";
import NotificationPreferences from "@/models/NotificationPreferences";
import User from "@/models/User";
import { ROUTE_PROJECTS } from "@/router";
import Logger from "@/shared/logger";
import { mdiAccountBox, mdiEmail, mdiPhone } from "@mdi/js";
import { Privilege, Role } from "@winnove/vue-wlib/enums";
import { useRepo } from "pinia-orm";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "Account",
  components: { AddressTextField },
  setup(props, context) {
    const logger = Logger.getInstance();
    const formAccount = ref(null);
    const m_isFormValid = ref(true);
    const m_user = ref(new User());
    const m_address = ref(new Address());
    const m_formBeingSubmitted = ref(false);
    const m_unavailableDates = ref();
    const m_todayDate = ref();
    const m_role = ref(Role.DEFAULT);
    const m_countries = ref([] as Country[]);
    const m_userNotificationPreferences = ref(new NotificationPreferences());

    onMounted(() => {
      (async () => {
        await AddressHelper.fetchAddresses();
        m_user.value = AuthHelper.getLoggedUser();
        const userId = m_user.value.id as number;
        const addressesRepo = useRepo(Address);
        m_address.value = addressesRepo
          .query()
          .where("userId", userId)
          .withAllRecursive()
          .first()!;

        await NotificationPreferencesHelper.fetchNotificationPreferences(
          userId
        );
        m_userNotificationPreferences.value = useRepo(
          NotificationPreferences
        ).all()[0];

        await CountryHelper.fetchCountries();

        m_countries.value = useRepo(Country).all();

        m_todayDate.value = new Date().toString();
        m_role.value = AuthHelper.getLoggedUser().role;
      })();
    });

    const isProductionRole = computed((): boolean => {
      return m_role.value == Role.PRODUCTION;
    });

    async function validateForm(): Promise<void> {
      m_formBeingSubmitted.value = true;

      // Update user.
      await UserHelper.updateUser(m_user.value as User);

      // Persist address.
      await AddressHelper.updateAddress(m_address.value as Address);

      logger.success("Profil mis à jour");

      // Force to re-render doctor name.
      m_user.value = AuthHelper.getLoggedUser();
      context.emit("update");
      m_formBeingSubmitted.value = false;
    }

    async function updateNotificationPreferences(): Promise<void> {
      m_formBeingSubmitted.value = true;

      if (m_userNotificationPreferences.value.id === -1) {
        await NotificationPreferencesHelper.createNotificationPreferences(
          m_userNotificationPreferences.value as NotificationPreferences
        );
      } else {
        await NotificationPreferencesHelper.updateNotificationPreferences(
          m_userNotificationPreferences.value as NotificationPreferences
        );
      }
      m_userNotificationPreferences.value = useRepo(
        NotificationPreferences
      ).all()[0];

      logger.success("Préférences de notifications mises à jour");
      m_formBeingSubmitted.value = false;
    }

    function updateUnavailableDates(value: string) {
      m_user.value.unavailableDates = value;
    }

    return {
      m_countries,
      formAccount,
      m_isFormValid,
      m_user,
      m_address,
      ROUTE_PROJECTS,
      m_formBeingSubmitted,
      validateForm,
      mdiPhone,
      mdiEmail,
      mdiAccountBox,
      m_unavailableDates,
      m_todayDate,
      updateUnavailableDates,
      isProductionRole,
      m_userNotificationPreferences,
      updateNotificationPreferences,
      Privilege,
    };
  },
});
</script>
