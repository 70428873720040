<template>
  <div>
    <v-file-input
      :label="p_title"
      counter
      multiple
      show-size
      accept="application/pdf"
      @change="onFilesChange"
      variant="outlined"
      :prepend-icon="null"
      :append-inner-icon="mdiFileUpload"
      chips
      :rules="[validateFilesInput]"
    ></v-file-input>
  </div>
</template>

<script>
import Logger from "@/shared/logger";
import { mdiFileUpload } from "@mdi/js";

export default {
  name: "InvoiceArea",
  props: {
    p_title: {
      type: String,
      required: true,
    },
  },
  setup() {
    return { mdiFileUpload };
  },
  data() {
    return {
      pdfFiles: [],
    };
  },

  methods: {
    onFilesChange(event) {
      const files = Array.from(event.target.files).filter(
        (file) => file.type === "application/pdf"
      );

      if (files.length) {
        this.pdfFiles = files;
        this.$emit("files-selected", files);
      } else {
        Logger.getInstance().error("Veuillez sélectionner des fichiers PDF.");
      }
    },
    validateFilesInput(value) {
      let ret = true;
      for (const element of value) {
        if (element.type !== "application/pdf") {
          ret = false;
          break;
        }
      }
      return ret || "Veuillez sélectionner des fichiers PDF.";
    },
  },
};
</script>
