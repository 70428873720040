<template>
  <v-expansion-panel class="bg-white">
    <v-expansion-panel-title class="py-6">
      <template #actions="{ expanded }">
        <div class="d-flex align-center">
          <span><u>Détails</u></span>
          <w-icon
            v-if="expanded"
            :icon="mdiChevronUp"
          ></w-icon>
          <w-icon
            v-else
            :icon="mdiChevronDown"
          ></w-icon>
        </div>
      </template>
      <w-row>
        <w-col
          cols="3"
          class="d-flex align-center"
        >
          <h3>
            {{ getMonthName(billingMonthSummary.month) }}
            {{ billingMonthSummary.year }}
          </h3>
        </w-col>
        <w-col
          cols="3"
          class="d-flex align-center"
        >
          <span>Mes jetons consommés : &nbsp;</span>
          <w-chip
            color="wLightPurple"
            variant="flat"
            density="compact"
            >{{ billingMonthSummary.consumedTokens }}</w-chip
          >
        </w-col>
        <w-col
          cols="3"
          class="d-flex align-center"
        >
          <span>Mes jetons crédités : &nbsp;</span>
          <w-chip
            color="wMintGreen"
            variant="flat"
            density="compact"
            >{{ billingMonthSummary.creditedTokens }}</w-chip
          >
        </w-col>
        <w-col
          cols="3"
          class="d-flex align-center"
          v-if="billingMonthSummary.invoicePending > 0"
        >
          <w-badge
            inline
            color="wOrange"
            dot
          ></w-badge>
          <span>&nbsp;Facture à régler</span>
        </w-col>
        <w-col
          cols="3"
          class="d-flex align-center"
          v-else-if="billingMonthSummary.invoicePaid > 0"
        >
          <w-badge
            inline
            color="#5DCC23"
            dot
          ></w-badge>
          <span>&nbsp;Facture réglée</span>
        </w-col>
      </w-row>
    </v-expansion-panel-title>

    <v-expansion-panel-text>
      <div class="d-flex">
        <w-chip
          color="wMintGreen"
          variant="flat"
          density="compact"
          >Mes jetons crédités</w-chip
        >
      </div>
      <BillingTableMonthHistoryConsumptionTable
        :billings="m_creditBillings"
        :isConsumption="false"
      ></BillingTableMonthHistoryConsumptionTable>

      <div class="d-flex">
        <w-chip
          color="wLightPurple"
          variant="flat"
          density="compact"
          >Mon historique de consommation</w-chip
        >
      </div>
      <BillingTableMonthHistoryConsumptionTable
        :billings="m_consumptionBillings"
        :isConsumption="true"
      ></BillingTableMonthHistoryConsumptionTable>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script setup lang="ts">
import BillingHelper, { BillingSummary } from "@/helpers/BillingHelper";
import Billing from "@/models/Billing";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { defineProps, ref, watch } from "vue";

const props = defineProps<{
  billingMonthSummary: BillingSummary;
  selected: boolean;
  userId: number;
}>();

const m_consumptionBillings = ref<Billing[]>([]);
const m_creditBillings = ref<Billing[]>([]);

watch(
  () => props.selected,
  async () => {
    await refresh();
  }
);

async function refresh() {
  if (props.selected) {
    const billings = await BillingHelper.getBillingsForMonth(
      props.userId,
      props.billingMonthSummary.year,
      props.billingMonthSummary.month
    );
    m_creditBillings.value = billings.filter((b) => !b.orderId);
    m_consumptionBillings.value = billings;
  }
}

function getMonthName(month: number) {
  const date = new Date();
  date.setMonth(month - 1);
  return date
    .toLocaleString("default", { month: "long" })
    .replace(/^\w/, (c) => c.toUpperCase());
}
</script>

<style scoped>
.v-expansion-panel-title--active {
  background-color: rgb(var(--v-theme-wGrey));
}
</style>
