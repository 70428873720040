<template>
  <div
    class="new-feature-menu"
    @mouseenter="panel = 0"
    @mouseleave="panel = null"
  >
    <w-expansion-panels
      v-model="panel"
      class="expansion-container"
    >
      <w-expansion-panel>
        <w-expansion-panel-title hide-actions>
          <w-icon
            :icon="mdiCreation"
            class="mr-2"
          />
          Nouveauté
        </w-expansion-panel-title>

        <w-expansion-panel-text>
          <w-btn
            variant="outlined"
            color="white"
            @click="emit('showNotificationPreferenceDialog')"
          >
            <p class="text-wrap">
              Gérer mes préférences de notifications par mails
            </p>
          </w-btn>
        </w-expansion-panel-text>
      </w-expansion-panel>
    </w-expansion-panels>
  </div>
</template>

<script lang="ts">
import { mdiCreation } from "@mdi/js";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "NewFeatureTooltip",
  components: {},
  props: {},
  setup(props, { emit }) {
    const panel = ref<number | null>(null);

    return {
      panel,
      mdiCreation,
      emit,
    };
  },
});
</script>

<style lang="scss">
.new-feature-menu {
  position: fixed;
  bottom: 0px;
  left: 35px;
  width: 240px;
  .v-expansion-panel-title {
    justify-content: start;
    padding: 10px 14px;
    font-size: 14px;
    font-weight: 700;
    color: rgb(var(--v-theme-wLavander)) !important;
    .v-icon {
      width: 24px;
      height: 24px;
    }
  }

  .v-expansion-panel {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .v-expansion-panel-text {
    transform-origin: bottom;
    .v-expansion-panel-text__wrapper {
      padding: 0px 14px 10px 14px;
    }
    .v-btn {
      font-size: 16px;
      padding: 0 10px;
      height: auto;
      transition: color 0.3s;
      &:hover {
        color: rgb(var(--v-theme-wLavander)) !important;
      }
    }
  }

  .v-card {
    transition: height 0.3s;
  }
}
</style>
