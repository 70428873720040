<template>
  <div class="mt-2">
    <h2>
      {{ year }}
    </h2>
    <v-expansion-panels @update:model-value="selectMonth($event)">
      <BillingTableMonthHistory
        class="mt-2"
        v-for="month in months"
        :key="month"
        :billing-month-summary="getBillingSummaryForMonth(month)"
        :selected="month === m_selectedMonth"
        :userId="userId"
      >
      </BillingTableMonthHistory>
    </v-expansion-panels>
  </div>
</template>

<script setup lang="ts">
import { BillingSummary } from "@/helpers/BillingHelper";
import { DataTableOptions } from "@/helpers/WinnoveHelper";
import { defineProps, onMounted, ref, watch } from "vue";

const props = defineProps<{
  billingsSummary: BillingSummary[];
  year: number;
  userId: number;
}>();

const DEFAULT_OPTIONS: DataTableOptions = {
  page: 1,
  itemsPerPage: 25,
  sortBy: [
    { key: "date", order: "desc" },
    { key: "cumulativeCredits", order: "asc" },
  ],
  groupBy: [],
  search: "",
};

const months = ref<number[]>([]);
const m_selectedMonth = ref<number | undefined>(undefined);

function selectMonth(idx: number | undefined) {
  if (idx === undefined) {
    m_selectedMonth.value = undefined;
  } else {
    m_selectedMonth.value = months.value[idx];
  }
}

function getBillingSummaryForMonth(month: number) {
  for (const bs of props.billingsSummary) {
    if (bs.month === month) {
      return bs;
    }
  }
  return {
    year: props.year,
    month,
    consumedTokens: 0,
    creditedTokens: 0,
    invoicePending: false,
    invoicePaid: false,
  };
}

onMounted(() => {
  refresh();
});

function refresh() {
  months.value = Array.from(
    new Set(props.billingsSummary.map((b) => b.month))
  ).sort((a, b) => b - a);
}

watch([() => props.billingsSummary, () => props.year], () => {
  refresh();
});
</script>

<style scoped>
.v-expansion-panel-title--active {
  background-color: rgb(var(--v-theme-wGrey));
}
</style>
