<template>
  <w-card class="bg-white preference-card">
    <div class="d-flex justify-space-between align-center">
      <div>
        <w-card-title>{{ p_title }}</w-card-title>
        <w-card-subtitle>{{ p_subtitle }}</w-card-subtitle>
      </div>
      <div>
        <w-card-actions>
          <w-select
            v-model="m_data"
            :items="m_emailPreferenceOptions"
            item-value="value"
            :item-props="itemProps"
            outlined
            hide-details
          />
        </w-card-actions>
      </div>
    </div>
  </w-card>
</template>

<script lang="ts">
import { EmailPreference } from "@winnove/vue-wlib/enums";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "PreferenceCard",
  props: {
    p_title: {
      type: String,
      required: true,
    },
    p_subtitle: {
      type: String,
      required: true,
    },
    p_canBeDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    p_data: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const m_emailPreferenceOptions = computed(() => {
      const baseOptions = [
        {
          value: EmailPreference.INSTANT,
          label: "Mails instantanés",
          sublabel: "Des e-mails immédiats pour chaque action.",
        },
        {
          value: EmailPreference.DAILY,
          label: "Résumé quotidien",
          sublabel:
            "Récapitulatif unique le soir regroupant toutes les actions.",
        },
      ];
      if (props.p_canBeDisabled) {
        baseOptions.push({
          value: EmailPreference.DISABLED,
          label: "Aucun mail",
          sublabel: "Ne pas recevoir les mails de ce type.",
        });
      }
      return baseOptions;
    });

    const m_data = computed({
      get: () => props.p_data,
      set: (value: number) => {
        context.emit("update:p_data", value);
      },
    });

    function itemProps(item: any) {
      return {
        title: item.label,
        subtitle: item.sublabel,
      };
    }

    return {
      m_emailPreferenceOptions,
      m_data,
      itemProps,
    };
  },
});
</script>

<style lang="scss">
.v-card.preference-card {
  padding: 20px 24px;

  .v-card-title {
    font-size: 16px;
    padding: 0;
    margin-bottom: 12px;
  }

  .v-card-subtitle {
    font-size: 14px;
    padding: 0;
  }

  .v-card-actions {
    width: 220px;
  }

  .v-list-item--active {
    background-color: white;
  }
}

.v-list-item-subtitle,
.v-list-item-title {
  font-weight: 500;
}
</style>
