import { EmailPreference } from "@winnove/vue-wlib/enums";
import BaseModel from "./BaseModel";

export default class NotificationPreferences extends BaseModel {
  static readonly entity = "NotificationPreferences";
  static readonly primaryKey = "id";

  static fields() {
    return {
      id: this.number(-1),
      userId: this.number(null),

      prescriptionIncomplete: this.number(EmailPreference.INSTANT), // Default to INSTANT
      projectValidationRequired: this.number(EmailPreference.INSTANT),
      orderToComplete: this.number(EmailPreference.INSTANT),
      orderCancellation: this.number(EmailPreference.INSTANT),
      newOrderReceived: this.number(EmailPreference.INSTANT),
      orderShipped: this.number(EmailPreference.INSTANT),
      unreadMessage: this.number(EmailPreference.INSTANT),
    };
  }

  declare id: number | null;
  declare userId: number | null;

  // Notification preference fields
  declare prescriptionIncomplete: EmailPreference;
  declare projectValidationRequired: EmailPreference;
  declare orderToComplete: EmailPreference;
  declare orderCancellation: EmailPreference;
  declare newOrderReceived: EmailPreference;
  declare orderShipped: EmailPreference;
  declare unreadMessage: EmailPreference;

  public serialize(): {} {
    const object: NotificationPreferences = Object.assign(
      new NotificationPreferences(),
      this
    );

    return object;
  }
}
