<template>
  <BaseDialog
    data-cy="address-dialog"
    ref="addressDialog"
    p_title="Informations de livraison"
  >
    <w-form
      ref="formAddress"
      class="mt-2"
      v-model="m_isFormValid"
      @submit.prevent="validate()"
    >
      <w-card-text>
        <w-row>
          <w-col md="6">
            <w-text-field
              v-model.trim="m_user.lastName"
              label="Nom"
              :prepend-inner-icon="mdiAccountBox"
              disabled
            ></w-text-field>
          </w-col>
          <w-col md="6">
            <w-text-field
              v-model.trim="m_user.firstName"
              label="Prénom"
              :prepend-inner-icon="mdiAccountBox"
              disabled
            ></w-text-field>
          </w-col>
        </w-row>
        <w-row>
          <w-col md="6">
            <w-text-field
              data-cy="address-dialog-phone"
              v-model.trim="m_user.phoneNumber"
              label="Téléphone"
              :prepend-inner-icon="mdiPhone"
              type="number"
              :rules="[(v) => (v && !!v.trim()) || 'Téléphone requis']"
              required
            ></w-text-field>
          </w-col>
        </w-row>
        <w-row>
          <w-col md="12">
            <AddressTextField
              :p_address="m_address.address"
              @update:p_address="m_address.address = $event"
              p_label="Adresse"
              :maxlength="m_addressSize"
              :counter="m_addressSize"
              :rules="[(v) => (v && !!v.trim()) || 'Adresse requise']"
              required
            ></AddressTextField>
          </w-col>
        </w-row>
        <w-row>
          <w-col md="12">
            <AddressTextField
              :p_address="m_address.address2"
              @update:p_address="m_address.address2 = $event"
              p_label="Adresse suite"
            />
          </w-col>
        </w-row>
        <w-row>
          <w-col md="6">
            <w-text-field
              v-model.trim="m_address.city"
              label="Ville"
              :rules="[(v) => (v && !!v.trim()) || 'Ville requise']"
              required
            ></w-text-field>
          </w-col>
          <w-col md="6">
            <w-text-field
              v-model.trim="m_address.postalCode"
              label="Code postal"
              type="number"
              :rules="[(v) => (v && !!v.trim()) || 'Code postal requis']"
              required
              onkeydown="return event.keyCode !== 69"
            ></w-text-field>
          </w-col>
        </w-row>
        <w-row>
          <w-col md="6">
            <w-select
              v-model="m_address.country"
              :items="m_countries"
              item-title="name"
              item-value="code"
              hide-details
              :rules="[(v) => (v && !!v.trim()) || 'Pays requis']"
              required
            ></w-select>
          </w-col>
        </w-row>
      </w-card-text>
      <w-card-actions>
        <w-row>
          <w-col
            md="8"
            offset-md="2"
          >
            <w-btn
              data-cy="address-dialog-btn"
              :disabled="!m_isFormValid"
              class="mt-2"
              type="submit"
              block
              :iconRight="mdiCheckCircle"
            >
              <div>Valider</div>
            </w-btn>
          </w-col>
        </w-row>
      </w-card-actions>
    </w-form>
  </BaseDialog>
</template>

<script lang="ts">
import AddressTextField from "@/components/shared/AddressTextField.vue";
import BaseDialog from "@/components/shared/BaseDialog.vue";
import AddressHelper from "@/helpers/AddressHelper";
import CountryHelper from "@/helpers/CountryHelper";
import Country from "@/models/Country";

import UserHelper from "@/helpers/UserHelper";
import WinnoveHelper from "@/helpers/WinnoveHelper";
import Address from "@/models/Address";
import User from "@/models/User";
import Logger from "@/shared/logger";
import { mdiAccountBox, mdiCheckCircle, mdiPhone } from "@mdi/js";
import { useRepo } from "pinia-orm";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "AddressDialog",
  components: { AddressTextField, BaseDialog },
  props: {
    p_user: {
      type: User,
      required: true,
    },
  },
  setup(props, context) {
    const m_isFormValid = ref(true);
    const m_user = ref(props.p_user);
    const m_address = ref(props.p_user.address);
    const m_addressSize = ref(35);
    const formAddress = ref<HTMLFormElement | null>(null);
    const addressDialog = ref<InstanceType<typeof BaseDialog> | null>(null);
    const m_countries = ref([] as Country[]);

    function show(): void {
      addressDialog.value?.show();
    }

    function hide(): void {
      addressDialog.value?.close();
    }

    async function close() {
      await UserHelper.updateUser(m_user.value as User);

      await AddressHelper.updateAddress(m_address.value as Address);
      hide();
      context.emit("AddressCompleted");
    }

    onMounted(async () => {
      try {
        await WinnoveHelper.fetchConfig();
        await CountryHelper.fetchCountries();

        m_countries.value = useRepo(Country).all();
      } catch (error) {
        Logger.getInstance().error(
          String(error),
          "Erreur lors de la récupération des pays"
        );
      }
    });

    async function validate(): Promise<void> {
      close();
    }

    return {
      m_countries,
      addressDialog,
      m_isFormValid,
      m_user,
      m_address,
      m_addressSize,
      formAddress,
      show,
      hide,
      close,
      validate,
      mdiAccountBox,
      mdiCheckCircle,
      mdiPhone,
    };
  },
});
</script>
