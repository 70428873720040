import PaginationFilter from "@/components/dashboard/tables/filterChip/PaginationFilter";
import Billing from "@/models/Billing";
import axios, { AxiosResponse } from "axios";
import WinnoveHelper, { DataTableOptions } from "./WinnoveHelper";

export interface BillingSummary {
  year: number;
  month: number;
  consumedTokens: number;
  creditedTokens: number;
  invoicePending: number;
  invoicePaid: number;
}

export default class BillingHelper {
  static async getRemainingTokens(
    userId?: number,
    p_options?: DataTableOptions,
    p_filters?: Array<PaginationFilter>
  ): Promise<{ remaining: Billing[]; count: number }> {
    const filters: { [key: string]: Array<number> } = {};
    if (p_filters) {
      for (const filter of p_filters) {
        filters[filter.dbQuery] = filter.getCheckedFilterValues();
      }
    }
    const serverOptions = p_options
      ? WinnoveHelper.dataTableOptions2ServerDataOptions(p_options)
      : {};
    if (userId)
      return await axios
        .get(`billings/remaining/${userId}`, {
          params: {
            ...serverOptions,
            search: p_options?.search ?? "",
            filters: JSON.stringify(filters),
          },
        })
        .then(async (p_response: AxiosResponse) => {
          const remaining: any = p_response.data.remaining;
          const count: number = p_response.data.count;
          return {
            remaining: remaining,
            count: count,
          };
        });
    else
      return await axios
        .get(`billings/remaining`, {
          params: {
            ...serverOptions,
            search: p_options?.search ?? "",
            filters: JSON.stringify(filters),
          },
        })
        .then(async (p_response: AxiosResponse) => {
          const remaining: any = p_response.data.remaining;
          // billings.credits are received as string from the server, so we need to convert them to number
          for (const element of remaining) element.credits = +element.credits;

          const count: number = p_response.data.count;
          return {
            remaining: remaining,
            count: count,
          };
        });
  }

  static async getBillingHistory(
    userId?: number,
    p_options?: DataTableOptions,
    p_filters?: Array<PaginationFilter>
  ): Promise<{ billings: Billing[]; count: number }> {
    const filters: { [key: string]: Array<number> } = {};
    if (p_filters) {
      for (const filter of p_filters) {
        filters[filter.dbQuery] = filter.getCheckedFilterValues();
      }
    }
    const serverOptions = p_options
      ? WinnoveHelper.dataTableOptions2ServerDataOptions(p_options)
      : {};

    const url: string = userId ? `billings/history/${userId}` : "billings/";

    return await axios
      .get(url, {
        params: {
          ...serverOptions,
          search: p_options?.search ?? "",
          filters: JSON.stringify(filters),
        },
      })
      .then(async (p_response: AxiosResponse) => {
        const billings: any = p_response.data.billing;
        const count: number = p_response.data.count;
        return {
          billings: billings,
          count: count,
        };
      });
  }

  static async getBillingSummary(userId: number): Promise<BillingSummary[]> {
    return await axios
      .get(`billings/summary/${userId}`)
      .then(async (p_response: AxiosResponse) => {
        const billingSummary: BillingSummary[] = p_response.data.billingSummary;
        return billingSummary;
      });
  }

  static async getBillingsForMonth(
    userId: number,
    year: number,
    month: number
  ): Promise<Billing[]> {
    return await axios
      .get(`billings/history/${userId}/${year}/${month}`)
      .then(async (p_response: AxiosResponse) => {
        const billings: Billing[] = p_response.data.billings;
        return billings;
      });
  }

  static async getBillingHistoryInvoice(
    userId?: number,
    p_options?: DataTableOptions,
    p_filters?: Array<PaginationFilter>
  ): Promise<{ billings: Billing[]; count: number }> {
    const filters: { [key: string]: Array<number> } = {};
    if (p_filters) {
      for (const filter of p_filters) {
        filters[filter.dbQuery] = filter.getCheckedFilterValues();
      }
    }
    const serverOptions = p_options
      ? WinnoveHelper.dataTableOptions2ServerDataOptions(p_options)
      : {};

    return await axios
      .get(`billings/invoicedHistory/${userId}`, {
        params: {
          ...serverOptions,
          search: p_options?.search ?? "",
          filters: JSON.stringify(filters),
        },
      })
      .then(async (p_response: AxiosResponse) => {
        const billings: any = p_response.data.billing;
        const count: number = p_response.data.count;
        return {
          billings: billings,
          count: count,
        };
      });
  }

  static async addBilling(
    p_newBilling: Billing,
    p_invoiceFiles: File[]
  ): Promise<Billing> {
    const formData = new FormData();
    formData.set("billing", JSON.stringify(p_newBilling.serialize()));

    p_invoiceFiles.forEach((file) => {
      formData.append("invoiceFiles", file);
    });

    return await axios
      .post(`billings`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (p_response: AxiosResponse) => {
        return p_response.data.billing;
      });
  }

  static async downloadInvoice(
    p_billing: Billing,
    p_path: string
  ): Promise<Blob> {
    const response = await axios.post(
      `billings/history/${p_billing.id}/invoices`,
      {
        s3Path: p_path,
      },
      {
        responseType: "blob",
      }
    );

    return response.data;
  }
  static async deleteBilling(p_billing: Billing): Promise<void> {
    await axios.delete("billings/" + p_billing.id, {});
  }

  static async updateBilling(
    p_billing: Billing,
    p_invoiceFiles: File[] = []
  ): Promise<Billing> {
    const formData = new FormData();
    formData.set("billing", JSON.stringify(p_billing));

    p_invoiceFiles.forEach((file) => {
      formData.append("invoiceFiles", file);
    });

    return await axios
      .put(`billings/${p_billing.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (p_response: AxiosResponse) => {
        return p_response.data.billing;
      });
  }
}
