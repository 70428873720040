<template>
  <w-container
    fluid
    style="max-width: 1200px; margin: 0 auto"
  >
    <!-- Summary -->
    <w-card class="pa-3 my-3">
      <w-row class="my-0 text-white">
        <w-col
          cols="1"
          class="token-icon"
        >
          <w-icon
            max-width="30px"
            :icon="mdiCheckCircle"
          ></w-icon>
        </w-col>
        <w-col>
          <h2>Jetons restants{{ m_billingUserName }} : {{ m_tokensLeft }}</h2>
        </w-col>
        <w-col>
          <w-btn
            block
            color="white"
            href="mailto:contact@winnovemed.com?subject=Demande de jetons&body=Bonjour,%0D%0A%0D%0AJe souhaiterais obtenir des jetons pour la plateforme Iform.%0D%0A%0D%0AMerci de votre retour.%0D%0A%0D%0ACordialement."
            >Demander des jetons</w-btn
          >
        </w-col>
      </w-row>
    </w-card>

    <w-alert
      v-if="m_tokensLeft < 5 && m_tokensLeft > 0"
      data-cy="billing-tokens-warning"
      class="my-3"
      type="warning"
      variant="tonal"
      prominent
    >
      Il vous reste moins de 5 jetons. Pensez à en recommander pour ne pas être
      bloqué.
    </w-alert>
    <!-- No more tokens -->
    <w-alert
      v-if="m_tokensLeft <= 0 && !isCurrentUserBlocked()"
      data-cy="billing-tokens-negative"
      class="my-3"
      type="error"
      prominent
      variant="tonal"
    >
      Vous n'avez plus de jetons. Pensez à en recommander pour ne pas être
      bloqué.
    </w-alert>
    <!-- User blocked - no tokens -->
    <w-alert
      v-else-if="isCurrentUserBlocked() && m_tokensLeft <= 0"
      data-cy="billing-tokens-blocked"
      class="my-3"
      type="error"
      variant="tonal"
      prominent
    >
      <strong>Votre compte est actuellement bloqué</strong> car vous n'avez plus
      de jetons.
      <br />
      <w-divider class="my-2"></w-divider>
      <w-icon :icon="mdiEmailOutline"></w-icon> :
      <a href="mailto:contact@winnovemed.com">contact@winnovemed.com</a>
      <br />
      <w-icon :icon="mdiPhone"></w-icon> :
      <a href="tel:+33244848644">+33 2 44 84 86 44</a>
    </w-alert>

    <!-- Billing history -->
    <div class="pa-3">
      <BillingTableYearHistory
        v-for="year in m_years"
        :year="year"
        :billingsSummary="getBillingSummaryForYear(year)"
        :userId="m_userId"
      ></BillingTableYearHistory>
    </div>
  </w-container>
</template>

<script lang="ts">
import { AuthHelper } from "@/helpers/AuthHelper";
import BillingHelper, { BillingSummary } from "@/helpers/BillingHelper";
import DateHelper from "@/helpers/DateHelper";
import UserHelper from "@/helpers/UserHelper";
import Billing from "@/models/Billing";
import User from "@/models/User";
import router from "@/router";
import { mdiCheckCircle, mdiEmailOutline, mdiPhone } from "@mdi/js";
import { useRepo } from "pinia-orm";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "BillingTable",
  components: {},
  props: {},
  setup() {
    const m_totalCount = ref(0);
    const m_tokensLeft = ref<number | undefined>(10);
    const m_billingsHistory = ref<Billing[]>([]);
    const m_billingSummary = ref<BillingSummary[]>([]);
    const m_years = ref<number[]>([]);
    const m_userId = ref(AuthHelper.getLoggedUser().id!);
    const m_billingUserName = ref("");

    onMounted(() => {
      refresh();
    });

    async function refresh(): Promise<void> {
      // check if we want the billing of another user
      const userIdParam = parseInt(
        Array.isArray(router.currentRoute.value.params.userId)
          ? router.currentRoute.value.params.userId[0]
          : router.currentRoute.value.params.userId
      );

      const userId = isNaN(userIdParam)
        ? AuthHelper.getLoggedUser().id!
        : userIdParam;

      if (userId !== AuthHelper.getLoggedUser().id) {
        await UserHelper.fetchUsers();
        const user = useRepo(User).query().where("id", userId).first();
        m_billingUserName.value = ` pour le Dr. ${user?.lastName} ${user?.firstName}`;
        m_userId.value = userId;
      }
      // Get remaining tokens
      const remainings: { remaining: Billing[]; count: number } =
        await BillingHelper.getRemainingTokens(userId);
      m_tokensLeft.value = remainings.remaining[0].credits;
      m_billingSummary.value = await BillingHelper.getBillingSummary(userId);
      m_years.value = [
        ...new Set(m_billingSummary.value.map((summary) => summary.year)),
      ].sort((a, b) => b - a);
    }

    function getBillingSummaryForYear(year: number): BillingSummary[] {
      return m_billingSummary.value.filter((summary) => summary.year === year);
    }

    function isCurrentUserBlocked() {
      const user = useRepo(User)
        .query()
        .where("id", AuthHelper.getLoggedUser().id)
        .first();
      if (!user) {
        return false;
      }
      return !!user.blocked;
    }

    return {
      m_billingsHistory,
      m_totalCount,
      m_tokensLeft,
      m_billingUserName,
      m_years,
      DateHelper,
      isCurrentUserBlocked,
      mdiEmailOutline,
      mdiPhone,
      mdiCheckCircle,
      getBillingSummaryForYear,
      m_userId,
    };
  },
});
</script>

<style scoped>
.token-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
