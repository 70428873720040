<template>
  <w-data-table
    :loading="m_isLoading"
    :headers="isConsumption ? m_consumptionTableHeaders : m_creditsTableHeaders"
    :items="billings"
    multi-sort
    :sort-by="[
      { key: 'date', order: 'desc' },
      { key: 'cumulativeCredits', order: 'asc' },
    ]"
    density="compact"
    class="bg-white"
  >
    <template #[`item.date`]="{ item }">
      <span>{{ DateHelper.formatDate(item.date) }}</span>
    </template>
    <template #[`item.comment`]="{ item }">
      <span>{{ formatComment(item.comment) }}</span>
    </template>
    <template #[`item.invoice`]="{ item }">
      <template
        v-if="item.invoicePath && JSON.parse(item.invoicePath).length > 0"
      >
        <template v-if="JSON.parse(item.invoicePath).length === 1">
          <w-btn
            color="primary"
            density="compact"
            @click="
              downloadSpecificInvoice(
                item,
                JSON.parse(item.invoicePath)[0].path
              )
            "
          >
            Télécharger la facture
          </w-btn>
        </template>

        <template v-else>
          <v-menu
            bottom
            offset-y
          >
            <template v-slot:activator="{ props }">
              <w-btn
                v-bind="props"
                color="primary"
                density="compact"
              >
                Télécharger les factures
              </w-btn>
            </template>

            <w-list density="compact">
              <w-list-item
                v-for="(invoice, index) in getInvoices(item)"
                :key="index"
                @click="downloadSpecificInvoice(item, invoice.path)"
                :prepend-icon="mdiDownload"
              >
                <w-list-item-title>{{
                  invoice.path.split("/").pop()
                }}</w-list-item-title>
              </w-list-item>
            </w-list>
          </v-menu>
        </template>
      </template>
    </template>
    <template #[`item.paid`]="{ item }">
      <div v-if="item.invoiced && !item.paid">
        <w-badge
          inline
          color="wOrange"
          dot
        ></w-badge>
        <span>&nbsp;En attente</span>
      </div>
      <div v-else-if="item.invoiced && item.paid">
        <w-badge
          inline
          color="#5DCC23"
          dot
        ></w-badge>
        <span>&nbsp;Réglée</span>
      </div>
    </template>
  </w-data-table>
</template>

<script setup lang="ts">
import BillingHelper from "@/helpers/BillingHelper";
import DateHelper from "@/helpers/DateHelper";
import Billing from "@/models/Billing";
import Logger from "@/shared/logger";
import { mdiDownload } from "@mdi/js";
import { defineProps, ref } from "vue";

const props = defineProps<{
  billings: Billing[];
  isConsumption: boolean;
}>();

const m_consumptionTableHeaders = [
  {
    title: "Date",
    key: "date",
    sortable: true,
    filterable: true,
  },
  {
    title: "Détails",
    key: "comment",
    sortable: false,
    filterable: false,
  },
  {
    title: "Évolution jetons",
    key: "credits",
    sortable: false,
    filterable: false,
  },
  {
    title: "# jetons à date",
    key: "cumulativeCredits",
    sortable: true,
    filterable: false,
  },
];
const m_creditsTableHeaders = [
  {
    title: "Date",
    key: "date",
    sortable: true,
    filterable: true,
  },
  {
    title: "Détails",
    key: "comment",
    sortable: false,
    filterable: false,
  },
  {
    title: "Évolution jetons",
    key: "credits",
    sortable: false,
    filterable: false,
  },
  {
    title: "Règlement",
    key: "paid",
    sortable: true,
    filterable: false,
  },
  {
    title: "Facture associée",
    key: "invoice",
    sortable: false,
    filterable: false,
  },
];

const m_isLoading = ref(false);

function formatComment(value: string) {
  const splittedComment = value.split(" ");
  const reference = splittedComment[splittedComment.length - 1];
  let comment: string = value;
  if (value.includes("Maxillaire") || value.includes("Mandibulaire")) {
    comment = value.includes("Maxillaire") ? "U" : "L";
    comment += `${reference} `;
    if (value.includes("Annulation")) {
      comment += " annulée";
    } else if (value.includes("Restauration")) {
      comment += " restaurée";
    }
  }
  return comment;
}

function getInvoices(item: Billing | null) {
  if (!item || !item.invoicePath) {
    return [];
  }
  try {
    return JSON.parse(item.invoicePath);
  } catch (e) {
    Logger.getInstance().error("Erreur lors de la récupération des factures");
    return [];
  }
}

async function downloadSpecificInvoice(item: Billing | null, path: string) {
  if (!item || !path) return;

  try {
    const invoiceBlob = await BillingHelper.downloadInvoice(item, path);

    const link = document.createElement("a");
    link.href = URL.createObjectURL(invoiceBlob);
    const filename = path.split("/").pop();
    link.download = filename || "facture.pdf";
    link.click();

    URL.revokeObjectURL(link.href);
  } catch (error) {
    Logger.getInstance().error("Erreur lors du téléchargement de la facture");
  }
}
</script>

<style scoped>
.v-expansion-panel-title--active {
  background-color: rgb(var(--v-theme-wGrey));
}
</style>
