<template>
  <div
    class="d-flex flex-column py-10"
    style="max-width: 1000px; width: 100%; justify-self: center"
  >
    <h2 class="mb-4">
      Préférence du Dr. {{ m_user?.lastName }} {{ m_user?.firstName }}
    </h2>
    <w-form
      ref="formNotificationPreferences"
      @submit.prevent="updateNotificationPreferences()"
    >
      <w-row>
        <w-col>
          <h3 class="mb-4">Notifications</h3>
        </w-col>
      </w-row>
      <w-row class="email-table">
        <w-col>
          <span class="obligatory">Prescription incomplète</span>
        </w-col>
        <w-col>
          <w-select
            v-model="m_userNotificationPreferences.prescriptionIncomplete"
            :items="
              m_user.privilege === Privilege.IN_OFFICE
                ? m_emailPreferenceOptionsWithDisabled
                : m_emailPreferenceOptions
            "
            item-value="value"
            item-title="label"
            outlined
            hide-details
          />
        </w-col>
      </w-row>
      <w-row class="email-table">
        <w-col>
          <span class="obligatory">Validation du projet requise</span>
        </w-col>
        <w-col>
          <w-select
            v-model="m_userNotificationPreferences.projectValidationRequired"
            :items="
              m_user.privilege === Privilege.IN_OFFICE
                ? m_emailPreferenceOptionsWithDisabled
                : m_emailPreferenceOptions
            "
            item-value="value"
            item-title="label"
            outlined
            hide-details
          />
        </w-col>
      </w-row>
      <w-row class="email-table">
        <w-col>
          <span class="obligatory">Commande à compléter</span>
        </w-col>
        <w-col>
          <w-select
            v-model="m_userNotificationPreferences.orderToComplete"
            :items="
              m_user.privilege === Privilege.IN_OFFICE
                ? m_emailPreferenceOptionsWithDisabled
                : m_emailPreferenceOptions
            "
            item-value="value"
            item-title="label"
            outlined
            hide-details
          />
        </w-col>
      </w-row>
      <w-row class="email-table">
        <w-col>
          <span class="obligatory">Annulation de commande</span>
        </w-col>
        <w-col>
          <w-select
            v-model="m_userNotificationPreferences.orderCancellation"
            :items="
              m_user.privilege === Privilege.IN_OFFICE
                ? m_emailPreferenceOptionsWithDisabled
                : m_emailPreferenceOptions
            "
            item-value="value"
            item-title="label"
            outlined
            hide-details
          />
        </w-col>
      </w-row>
      <w-row class="email-table">
        <w-col> Nouvelle commande reçue </w-col>
        <w-col>
          <w-select
            v-model="m_userNotificationPreferences.newOrderReceived"
            :items="m_emailPreferenceOptionsWithDisabled"
            item-value="value"
            item-title="label"
            outlined
            hide-details
          />
        </w-col>
      </w-row>
      <w-row class="email-table">
        <w-col> Expédition en cours </w-col>
        <w-col>
          <w-select
            v-model="m_userNotificationPreferences.orderShipped"
            :items="m_emailPreferenceOptionsWithDisabled"
            item-value="value"
            item-title="label"
            outlined
            hide-details
          />
        </w-col>
      </w-row>
      <w-row class="email-table">
        <w-col> Message non lu </w-col>
        <w-col>
          <w-select
            v-model="m_userNotificationPreferences.unreadMessage"
            :items="m_emailPreferenceOptionsWithDisabled"
            item-value="value"
            item-title="label"
            outlined
            hide-details
          />
        </w-col>
      </w-row>
      <w-row class="my-8 justify-center">
        <w-col sm="3">
          <w-btn
            color="wGrey"
            block
            @click="setByDefault"
            :loading="m_formBeingSubmitted"
            >Par défaut
          </w-btn>
        </w-col>
        <w-col sm="3">
          <w-btn
            color="primary"
            type="submit"
            block
            :loading="m_formBeingSubmitted"
            >Enregistrer
          </w-btn>
        </w-col>
      </w-row>
    </w-form>
    <div class="mt-10">
      <w-row>
        <w-col>
          <h3 class="mb-2">Historique des mails envoyés</h3>
        </w-col>
        <w-col sm="3">
          <w-text-field
            v-model="m_search"
            placeholder="Rechercher..."
            color="primary"
            class="text-primary"
            base-color="primary"
            single-line
            clearable
            hide-details
            :prepend-inner-icon="mdiMagnify"
          />
        </w-col>
      </w-row>
      <w-row>
        <w-col>
          <w-data-table-server
            class="mt-4 text-primary"
            :headers="m_headers"
            :items="m_emailHistory"
            item-key="id"
            :items-length="m_emailHistoryCount"
            :search="m_search"
            :page="m_options.page"
            :items-per-page="m_options.itemsPerPage"
            :sortBy="m_options.sortBy"
            @update:options="optionsChanged"
          >
            <template #[`item.date`]="{ item }">
              <span class="font-weight-bold">{{
                DateHelper.formatDateWithHours(item.date)
              }}</span>
            </template>
            <template #[`item.subject`]="{ item }">
              <span class="font-weight-bold">{{ item.subject }}</span>
            </template>
            <template #[`item.emailType`]="{ item }">
              <w-chip
                variant="flat"
                color="secondary"
                class="text-primary"
                >{{ getTypeText(item.emailType) }}</w-chip
              >
            </template>
          </w-data-table-server>
        </w-col>
      </w-row>
    </div>
  </div>
</template>

<script lang="ts">
import CookieHelper from "@/helpers/CookieHelper";
import DateHelper from "@/helpers/DateHelper";
import EmailHistoryHelper from "@/helpers/EmailHistoryHelper";
import NotificationPreferencesHelper from "@/helpers/NotificationPreferencesHelper";
import { DataTableOptions } from "@/helpers/WinnoveHelper";
import EmailHistory from "@/models/EmailHistory";
import NotificationPreferences from "@/models/NotificationPreferences";
import User from "@/models/User";
import router from "@/router";
import Logger from "@/shared/logger";
import { mdiMagnify } from "@mdi/js";
import {
  EmailPreference,
  EmailTypeString,
  Privilege,
} from "@winnove/vue-wlib/enums";
import { useRepo } from "pinia-orm";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "UserEmailSummary",
  props: {},
  setup(props, context) {
    const logger = Logger.getInstance();
    const m_user = ref(new User());
    const m_userNotificationPreferences = ref(new NotificationPreferences());
    const m_formBeingSubmitted = ref(false);
    const m_emailHistory = ref<EmailHistory[]>([]);
    const m_emailHistoryCount = ref(0);
    const DEFAULT_OPTIONS: DataTableOptions = {
      page: 1,
      itemsPerPage: 10,
      sortBy: [{ key: "date", order: "desc" }],
      groupBy: [],
      search: "",
    };
    const m_options = ref<DataTableOptions>(
      CookieHelper.getCookie("EmailHistoryDashboardOptionsV3")
        ? JSON.parse(CookieHelper.getCookie("EmailHistoryDashboardOptionsV3")!)
        : DEFAULT_OPTIONS
    );
    const m_search = ref<string>(m_options.value.search);

    const m_headers = [
      {
        title: "DATE",
        align: "start",
        value: "date",
        sortable: true,
      },
      {
        title: "OBJET",
        align: "start",
        value: "subject",
        sortable: false,
      },
      {
        title: "TYPE",
        align: "start",
        value: "emailType",
        sortable: true,
      },
    ];

    const m_emailPreferenceOptions = [
      {
        value: EmailPreference.INSTANT,
        label: "Mails instantanés",
      },
      {
        value: EmailPreference.DAILY,
        label: "Résumé quotidien",
      },
    ];
    const m_emailPreferenceOptionsWithDisabled = [
      {
        value: EmailPreference.INSTANT,
        label: "Mails instantanés",
      },
      {
        value: EmailPreference.DAILY,
        label: "Résumé quotidien",
      },
      {
        value: EmailPreference.DISABLED,
        label: "Désactivé",
      },
    ];

    onMounted(() => {
      (async () => {
        refresh();
      })();
    });

    async function refresh(): Promise<void> {
      const userId: number = parseInt(
        router.currentRoute.value.params.id as string
      );
      m_user.value = useRepo(User).find(userId) as User;
      if (!m_user.value || m_user.value.privilege === Privilege.ADMIN) {
        router.push("/admin/users");
        return;
      }

      await NotificationPreferencesHelper.fetchNotificationPreferences(userId);
      m_userNotificationPreferences.value =
        NotificationPreferencesHelper.getNotificationPreferences(userId);

      const emailHistory: { emailHistory: EmailHistory[]; count: number } =
        await EmailHistoryHelper.getEmailHistory(userId, m_options.value);
      m_emailHistory.value = emailHistory.emailHistory;
      m_emailHistoryCount.value = emailHistory.count;
    }

    function optionsChanged(options: DataTableOptions): void {
      m_options.value = options;
      _onOptionsChanged();
    }

    async function _onOptionsChanged(): Promise<void> {
      CookieHelper.setCookie("BillingDashboardOptionsV3", m_options.value);
      refresh();
    }

    function setByDefault() {
      m_userNotificationPreferences.value = new NotificationPreferences({
        userId: m_userNotificationPreferences.value.userId,
        id: m_userNotificationPreferences.value.id,
      });
    }

    async function updateNotificationPreferences(): Promise<void> {
      m_formBeingSubmitted.value = true;

      if (m_userNotificationPreferences.value.id === -1) {
        await NotificationPreferencesHelper.createNotificationPreferences(
          m_userNotificationPreferences.value as NotificationPreferences
        );
      } else {
        await NotificationPreferencesHelper.updateNotificationPreferences(
          m_userNotificationPreferences.value as NotificationPreferences
        );
      }
      m_userNotificationPreferences.value = useRepo(
        NotificationPreferences
      ).all()[0];

      logger.success("Préférences de notifications mises à jour");
      m_formBeingSubmitted.value = false;
    }

    function getTypeText(type: number): string {
      return EmailTypeString[type];
    }

    return {
      m_userNotificationPreferences,
      m_emailPreferenceOptions,
      m_emailPreferenceOptionsWithDisabled,
      m_user,
      setByDefault,
      m_formBeingSubmitted,
      updateNotificationPreferences,
      m_headers,
      m_emailHistory,
      m_emailHistoryCount,
      m_options,
      m_search,
      optionsChanged,
      DateHelper,
      getTypeText,
      mdiMagnify,
      Privilege,
    };
  },
});
</script>

<style lang="scss" scoped>
.email-table {
  background-color: white;
  .v-col:first-child {
    padding: 18px 42px;
    font-size: 16px;
    font-weight: 700;
  }
  .v-col {
    border: 1px solid #bfbfbf;
    padding: 10px 0;
    .obligatory {
      opacity: 0.5;
    }
    .v-select {
      width: 200px;
      margin: auto;
    }
  }
}
</style>
